@import '~antd/dist/antd.css';

$secondary-color: #40a9ff;
$primary-color: #1890ff; // 全局主色
$link-color: #1890ff; // 链接色
$success-color: #52c41a; // 成功色
$warning-color: #faad14; // 警告色
$error-color: #f5222d; // 错误色

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

.highlight {
  color: #c41d7f;
  font-weight: bold;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.ant-btn.ant-btn-link {
  height: 16px !important;
  padding: 0 4px !important;
}

.table {
  table {
    table-layout: fixed;
    width: 100%;
    border-right: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;
    text-align: center;
    font-size: 14px;

    th {
      border-left: 1px solid #f0f0f0;
      border-top: 1px solid #f0f0f0;
      background: #f6f6f6;
    }

    td {
      border-left: 1px solid #f0f0f0;
      border-top: 1px solid #f0f0f0;
    }

    th,
    td {
      padding: 6px 12px;
      word-wrap: break-word;
    }
  }
}

.ant-table-thead > tr > th {
  font-weight: 600 !important;
  background: #f6f6f6 !important;
}

.ant-btn.ant-btn-link {
  padding: 4px 6px;
}

.ant-tree-treenode-selected::before {
  border-radius: 2px;
}

.ant-page-header-heading-title {
  font-size: 16px !important;
}

.ant-space-item {
  .ant-row {
    margin-bottom: 0 !important;
  }
}

.ant-modal-wrap {
  overflow-y: auto;
  overflow-x: hidden;
}

.ant-btn.ant-btn-link {
  line-height: 0;
  height: 0;
}

.ant-input-number,
.ant-picker {
  width: 100% !important;
}

.ant-tabs-nav-list {
  padding: 0 12px;
}

.ant-tabs-left.ant-tabs-card {
  height: 100%;

  .ant-tabs-nav-list {
    padding: 0;
  }

  .ant-tabs-tab {
    min-width: 120px;
    border-top: 0 !important;
    margin: 0 !important;
    justify-content: right;
    background-color: #f5f5f5 !important;
    background: #f5f5f5 !important;
    padding: 10px 20px !important;
  }

  .ant-tabs-tab-active {
    background-color: #fff !important;
    background: #fff !important;
  }
}
.ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background-color: #e6f4ff;
}

// 侧边tree滚动条样式
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
::-webkit-scrollbar-track {
  border-radius: 4px;
  width: 0px; //背景条颜色无
}
::-webkit-scrollbar-thumb {
  background-color: #999;
  border-radius: 4px;
}
